<template>
  <div>
    <el-form :model="form"
             ref="formRefs"
             :rules="rules"
             label-width="100px">
      <el-row :gutter="10"
              style="display:flex;flex-wrap:wrap">
        <el-col :span="8">
          <el-form-item label="题目编码:"
                        prop="questionCode">
            <el-input clearable
                      style="width:100%"
                      v-model="form.questionCode"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="8"
                prop="questionEnable">
          <el-form-item label="题目可用:">
            <el-select style="width:100%"
                       v-model="form.questionEnable"
                       placeholder="是否可用">
              <el-option v-for="item in keyongList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- {{name}} -->
        <!-- 本题带有题头的时候存在的选项  开始 -->
        <el-col :span="8"
                v-if=" name == '本题带有题头'">
          <el-form-item label="题头编码:"
                        prop="questionCodeHead">
            <el-input style="width:100%"
                      v-model="form.questionCodeHead"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
        <el-col :span="8"
                v-if="name == '本题带有题头'">
          <el-form-item label="序号:"
                        prop="questionSerialHead">
            <el-input clearable
                      style="width:100%"
                      v-model="form.questionSerialHead"
                      placeholder="请输入" />
          </el-form-item>
        </el-col>
        <!-- 本题带有题头的时候存在的选项  结束 -->

        <!-- 题目类型 当没有题头和本题带有题头的时候出现的选项 开始 -->
        <el-col :span="8"
                v-if="name == '无题头，独立选择' || name == '本题带有题头'">
          <el-form-item label="题目类型:"
                        prop="questionType">
            <el-select style="width:100%"
                       v-model="form.questionType "
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in selects.question_types"
                         :key="item.question_type_id"
                         :label="item.question_type_name"
                         :value="item.question_type_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目类别:"
                        prop="questionCategory">
            <el-select style="width:100%"
                       v-model="form.questionCategory "
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in selects.question_categories"
                         :key="item.question_category_id"
                         :label="item.question_category_name"
                         :value="item.question_category_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目难度:"
                        prop="questionDifficulty">
            <el-input clearable
                      style="width:100%"
                      type="number"
                      :min="1"
                      :max="5"
                      v-model="form.questionDifficulty"
                      placeholder="请输入" />

          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目分数:"
                        prop="questionScore">
            <el-input clearable
                      :disabled="$parent.name == '本题就是一个题头'"
                      placeholder="(当题目为题目头时，输入分数为0）"
                      type="number"
                      :min="0"
                      v-model.number="form.questionScore" />

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="考点:">
            <el-input clearable
                      placeholder="请选择"
                      id="zhishidian"
                      @click.native="showPoint"
                      readonly
                      style="width:100%" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="核心素养:">
            <!-- <el-select style="width:100%"
                       v-model="form.capability_data"
                       multiple
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in capability"
                         :key="item.capability_id"
                         :label="item.capability_name"
                         :value="item.capability_id">
              </el-option>
            </el-select> -->
            <el-cascader v-model="form.capability_data"
                         style="width: 100%"
                         :options="cascaderOptions"
                         :props="{ value: 'value', label: 'label',emitPath:true, children: 'children', multiple: true, }"
                         clearable
                         placeholder="选择">

            </el-cascader>

          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="章节:">
            <el-input clearable
                      placeholder="请选择"
                      id="zhangjie"
                      @click.native="showSection"
                      readonly
                      style="width:100%" />
          </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
    <PointDialog ref="pointDialogRef" />
    <!-- <SectionDialog ref="SectionDialogRef" /> -->

  </div>
</template>

<script>
import PointDialog from './pointDialog.vue'


export default {
  props: ['name', 'selects', 'parentForm'],
  watch: {
    name () {

    }
  },
  watch: {
    'form.subject_id': {
      handler () {
        this.$refs.pointDialogRef.getPointList()
      },
      deep: true
    }
  },
  data () {
    return {
      rules: {
        questionCode: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        questionEnable: [
          { required: false, message: '请输入', trigger: 'change' },
        ],
        questionCodeHead: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        questionSerialHead: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        questionType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        questionCategory: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        questionDifficulty: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        questionScore: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      subject_id: '',
      form: {
        questionCode: '',
        questionEnable: 1,
        questionCodeHead: '',
        questionSerialHead: '0',
        questionType: '',
        questionCategory: '',
        questionDifficulty: '3',
        questionScore: '',
        knowledge: [],
        chapters: []
      },
      options: [],
      keyongList: [{ label: '可用', value: 1 }, { label: '不可用', value: 0 }],
      secondLevelList: [],
      // 存放修改的时候回显的数组
      editArr: [],
      pointArr: [],
      capability: [],
      cascaderOptions: []

    }
  },
  components: {
    PointDialog,

  },
  created () {
    this.subject_id = this.$parent.form.subject_id
  },
  watch: {
    subject_id: {
      handler () {
        if (this.subject_id) {
          this.getCapability()
        }
      }
    }
  },
  methods: {
    resetForm (code) {
      this.$refs.formRefs.resetFields()
      this.$nextTick(() => {
        if (this.name == '本题就是一个题头') {
          this.form.questionScore = 0
        }
        this.form.questionCode = code

      })
    },
    showPoint () {
      this.$refs.formRefs.validate(val => {
        if (!val) return false
        //处理异步数据
        const getPointListPromise = new Promise((resolve, reject) => {
          this.$refs.pointDialogRef.getPointList(resolve)
        })
        // 等待数据请求完成后再打开对话框
        getPointListPromise.then(() => {
          if (this.form.knowledge.length) {
            this.$refs.pointDialogRef.editArr =
              JSON.parse(JSON.stringify(this.form.knowledge))
          }
          this.$refs.pointDialogRef.dialogVisible = true
        })
      })
    },
    getCapability () {
      console.log(this.subject_id)
      this.$http({
        url: '/api/v1/question/capabilities',
        method: 'get',
        params: {
          subject_id: this.subject_id,
        }
      }).then(res => {
        // this.capability = res.data.list
        console.log(res, "res123");
        const formData = res.data.list.map(item => ({
          value: item.capability_id,
          label: item.capability_name,
          children: item.child ? item.child.map(child => ({
            value: child.capability_id,
            label: child.capability_name
          })) : ''
        }));
        this.cascaderOptions = formData;
      })
    },
    // showSection () {
    //   this.$refs.formRefs.validate(val => {
    //     if (!val) return false
    //     this.$refs.SectionDialogRef.dialogVisible = true
    //     this.$refs.SectionDialogRef.getsectionList()
    //     if (this.form.chapters.length) {
    //       this.$refs.SectionDialogRef.editArr = JSON.parse(JSON.stringify(this.form.chapters))
    //     }
    //   })
    // },
    getsectionList () {
      this.$refs.SectionDialogRef.getsectionList()
    },
    refresh () {
      this.$refs.formRefs.resetFields()
    },
    setPoint (arr) {
      var ozhishidian = document.getElementById('zhishidian')
      let str = ''
      arr.forEach(item => {
        if (item.level == 1) {
          str += item.label + ':' + item.knowledge_score + ','
        } else {
          str += item.label + ','
        }
      })
      ozhishidian.value = str
      this.form.knowledge = arr
    },

    validateForm () {
      setTimeout(() => {
        this.$refs.formRefs.validate(val => {
          if (this.form.questionScore == 0 && this.$parent.name != '本题就是一个题头') {
            this.$message.warning('请填写题目分数')
            return
          }
          if (!val) {
            return
          } else {
            // 知识点
            let allKnowledge_score = 0
            this.form.knowledge.forEach(item => {
              allKnowledge_score += Number(item.knowledge_score)
            });
            if (allKnowledge_score > Number(this.form.questionScore)) {
              return this.$message.warning('考点分数总和大于题目分数，请修改')
            }
            this.$parent.save1()
          }
        })
      }, 200);
    }
  }
}
</script>

<style lang="scss" scoped>
.div_input {
  display: flex;
  height: 36px;
  color: #606266;
}
::v-deep .el-select .el-tag__close.el-icon-close::before {
  display: none;
}
::v-deep .el-tag.el-tag--info .el-tag__close {
  display: none;
}
</style>