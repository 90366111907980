<template>
  <div>
    <el-dialog title="考点"
               :visible.sync="dialogVisible"
               v-dialogDrag
               v-if="dialogVisible"
               width="500px"
               :close-on-click-modal="false"
               :lock-scroll="false"
               :before-close="handleClose">
      <div class="cascader_warp">
        <el-row class="row_center">
          <el-cascader placeholder="请选择"
                       style="width:400px"
                       clearable
                       ref="cascaderRef"
                       v-model="cascaderList"
                       :options="pointList"
                       :props="{
                       multiple: true,
                       label:'knowledge_desc',
                       value:'knowledge_id',
                       children:'child',
                       emitPath:true}"
                       filterable>
          </el-cascader>
        </el-row>
      </div>
      <div class="line_input_warp">
        <template v-for="(item, index) in list">
          <el-row :gutter="10"
                  style="margin-right:0"
                  :key="index"
                  :label="item.label"
                  v-if="item.level === 1">
            <el-col class="left_label"
                    :span="12">{{item.label}}</el-col>
            <el-col style="text-align:right;padding-left:3px"
                    :span="12">
              <el-input-number v-model="item.knowledge_score"
                               @change="handleChange"
                               :min="0"
                               :max="100"
                               label="描述文字">
              </el-input-number>
            </el-col>
          </el-row>
        </template>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      cascaderList: [],
      pointList: [],
      list: [],
      editArr: [],
      copyList: [],
      arr: []
    }
  },
  watch: {
    cascaderList: {
      handler (newVal, oldVal) {
        for (let index = 0; index < this.cascaderList.length; index++) {
          if (!Array.isArray(this.cascaderList[index])) {
            this.cascaderList.splice(index, 1)
            index--
          }
        }
        newVal.forEach(item => {
          var knowledge = this.copyList[item[0]]
          // 处理最顶级，如果list里面存在的话就不需要再push
          if (Array.isArray(item) && item.length &&
            this.list.findIndex(kno => kno.knowledge_id == knowledge.knowledge_id) == -1) {
            let obj = {
              knowledge_score: 0,
              knowledge_id: knowledge.knowledge_id,
              label: knowledge.knowledge_desc || knowledge.label,
              knowledge_parent_id: knowledge.knowledge_parent_id,
              level: knowledge.level
            }
            this.list.push(obj)
          }
        })
        // 取出每一项最顶级
        var topKnowledge = newVal.map(item => {
          return item[0]
        })
        // 反查最上级的子级是否选中
        for (let index = 0; index < this.list.length; index++) {
          if (topKnowledge.findIndex(item => item == this.list[index].knowledge_id) == -1) {
            this.list.splice(index, 1)
            index--
          }
        }
      },
      deep: true
    },
    dialogVisible () {
      this.$nextTick(() => {
        var model = document.getElementsByClassName('v-modal')[0]
        model.style['display'] = 'none';
      })
      // if (this.dialogVisible) {
      //   this.getPointList()
      // }
    },
    editArr: {
      handler () {
        this.list = []
        // 用于临时存起来 然后赋值给cascaderList 防止多次触发监听
        this.arr = []
        this.$set(this, 'cascaderList', [])

        for (let index = 0; index < this.editArr.length; index++) {
          const item = this.editArr[index];
          this.arr[this.arr.length] = []
          if (item.level != 1 && this.copyList[item.knowledge_id] && !this.copyList[item.knowledge_id].child) {
            this.searchParent(item, this.arr.length - 1)
            this.$set(this, 'cascaderList', this.arr)
            this.editArr.forEach(row => {
              if (row.level == 1 && this.list.findIndex(item => item.knowledge_id == row.knowledge_id) == -1) {
                var obj = {
                  knowledge_score: row.knowledge_score,
                  knowledge_id: row.knowledge_id,
                  label: row.label || row.knowledge_desc,
                  knowledge_parent_id: row.knowledge_parent_id,
                  level: row.level
                }
                this.list.push(obj)
              }
            })
          }
        }
      },
      deep: true
    },

  },
  mounted () {
    this.getPointList()
  },
  methods: {
    searchParent (item, index) {
      this.arr[index].unshift(item.knowledge_id)
      if (item.level != 1) {
        var row = this.copyList[item.knowledge_parent_id]
        this.arr[index].unshift(row.knowledge_id)
        if (row.level != 1) {
          var row = this.copyList[row.knowledge_parent_id]
          this.arr[index].unshift(row.knowledge_id)
        }
      }
    },
    async handleSave () {
      if (this.list.length == 1) {
        this.list[0].knowledge_score = this.$parent.form.questionScore
      }
      let list = JSON.parse(JSON.stringify(this.list))

      // 将所有选中的项 往list里面存   用于回显
      this.cascaderList.forEach(item => {
        var other_knowledge = this.copyList[item[item.length - 1]]
        if (other_knowledge && list.findIndex(row => row.knowledge_id == other_knowledge.knowledge_id) == -1) {
          list.push({
            knowledge_score: 0,
            knowledge_id: other_knowledge.knowledge_id,
            label: other_knowledge.knowledge_desc,
            knowledge_parent_id: other_knowledge.knowledge_parent_id,
            level: other_knowledge.level
          })
          let copyItem = this.copyList[other_knowledge.knowledge_parent_id]
          if (other_knowledge.knowledge_parent_id && copyItem.level != 1 && list.findIndex(row => row.knowledge_id == copyItem.knowledge_id) == -1) {
            list.push({
              knowledge_score: 0,
              knowledge_id: copyItem.knowledge_id,
              label: copyItem.knowledge_desc,
              knowledge_parent_id: copyItem.knowledge_parent_id,
              level: copyItem.level
            })
            let cItem = this.copyList[copyItem.knowledge_parent_id]
            if (copyItem.knowledge_parent_id && cItem.level != 1 && list.findIndex(row => row.knowledge_id == cItem.knowledge_id) == -1) {
              list.push({
                knowledge_score: 0,
                knowledge_id: cItem.knowledge_id,
                label: cItem.knowledge_desc,
                knowledge_parent_id: cItem.knowledge_parent_id,
                level: cItem.level
              })

            }
          }
        }
      })

      var ozhishidian = document.getElementById('zhishidian')
      let str = ''
      let allScore = 0
      var isActive = false
      list.forEach(item => {
        if (item.level == 1) {
          isActive = true
          str += item.label + ':' + item.knowledge_score + ','
          allScore += Number(item.knowledge_score)
        } else {
          str += item.label + ','
        }
      })
      if (isActive && allScore != this.$parent.form.questionScore) {
        return this.$message.warning('考点总分不等于题目总分！')
      }
      if (this.$route.path == '/examinationEntry/examinationEntry/edit') {
        // this.$http({
        //   url: '/api/v1/question/edit_knowledge_score',
        //   method: 'post',
        //   data: {
        //     question_id: this.$parent.form.questionId,
        //     knowledge: list
        //   }
        // }).then(res => {
        //   this.$notify({
        //     title: '提示',
        //     message: '修改成功!',
        //     type: 'success'
        //   });
        //   this.handleClose()
        // })
        await this.editKnowledge(list)
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
      }
      this.$parent.form.knowledge = JSON.parse(JSON.stringify(list))
      ozhishidian.value = str
      this.$router.go(1)
      this.handleClose()
    },
    editKnowledge (list) {
      return this.$http({
        url: '/api/v1/question/edit_knowledge_score',
        method: 'post',
        data: {
          question_id: this.$parent.form.questionId,
          knowledge: list
        }
      })
    },
    handleClose () {
      this.list = []
      this.editArr = []
      this.$set(this, 'cascaderList', [])
      this.$refs.cascaderRef.$refs.panel.clearCheckedNodes();
      this.dialogVisible = false
    },
    handleChange (num) {
      // 在这里处理类型不同的情况
    },
    getPointList (callback) {
      let id = this.$parent.subject_id || this.$parent.form.subject_id
      if (!id) return
      this.$http({
        url: '/api/v1/question/knowledge',
        method: 'get',
        params: {
          subject_id: this.$parent.subject_id
        }
      }).then(res => {
        this.pointList = res.data.list
        for (const item of this.flatList(JSON.parse(JSON.stringify(res.data.list)))) {
          this.copyList[item.knowledge_id] = item
        }
        if (typeof callback === 'function') { // 确保回调函数是一个有效的函数
          callback() // 数据请求完成后调用回调函数
        }
      })
    },
    flatList (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.flatList(list[i].child))
        }
      }
      return allList
    },
  }
}
</script>

<style lang="scss" scoped>
.cascader_warp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row_center {
  display: flex;
  justify-content: center;
  height: 36px;
  overflow: hidden;
}
::v-deep .el-cascader__tags {
  height: 36px;
  overflow: hidden;
  margin-top: 6px;
}
::v-deep .el-tag__close {
  display: none;
}
::v-deep .el-input__inner {
  height: 36px !important;
}
.left_label {
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}
.line_input_warp {
  width: 400px;
  margin: 0 auto;
  .el-row {
    line-height: 36px;
    margin: 10px 0;
  }
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-cascader__tags .el-tag {
  // background: white;
  // display: none;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}

// ::v-deep .el-dialog__headerbtn {
//   display: none;
// }

::v-deep .el-dialog__wrapper {
  overflow: visible;
  width: 500px !important;
}
::v-deep .el-dialog {
  margin-left: 50vw;
  transform: translateX(-50%);
}
</style>